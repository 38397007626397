import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  CustomNavLinkContact,
  Label,
  Outline,
  Span,
  LogoText
} from "./styles";

const Header = ({ t }: { t: TFunction }) => {
  const [visible, setVisibility] = useState(false);

  const [scrollOnTOp, setScrollTop] = useState(true);
  const changeNavbarColor = () => {
      if (window.scrollY >= 80 * 3) {
          setScrollTop(false);
      } else {
          setScrollTop(true);
      }
  };
  window.addEventListener("scroll", changeNavbarColor);
  const navbarTransparent = scrollOnTOp ? "navbarTransparent" : "navbarSolid";



  const toggleButton = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    return (
        <>
            {/* <CustomNavLinkSmall
                className={`navlink ${navbarTransparent}`}
                onClick={() => scrollTo("sustainability")}
            >
                <Span>{t("Sustainability")}</Span>
            </CustomNavLinkSmall>
            <CustomNavLinkSmall
                className={`navlink ${navbarTransparent}`}
                onClick={() => scrollTo("mission")}
            >
                <Span>{t("Mission")}</Span>
            </CustomNavLinkSmall>
            <CustomNavLinkSmall
                className={`navlink ${navbarTransparent}`}
                onClick={() => scrollTo("partners")}
            >
                <Span>{t("Partners")}</Span>
            </CustomNavLinkSmall> */}

            {/* <CustomNavLinkSmall className={`navlink ${navbarTransparent}`} onClick={() => scrollTo("waitinglist")}>
                <Span>{t("Waiting List")}</Span>
            </CustomNavLinkSmall> */}
            <CustomNavLinkContact
                className={`navlink navlink-contact ${navbarTransparent}`}
                onClick={() => scrollTo("waitinglist")}
            >
                <Span>
                    <Button no_shadow={true}>{t("Partner With Us")}</Button>
                </Span>
            </CustomNavLinkContact>
        </>
    );
  };

  return (
    <HeaderSection className={`navbar fixed-top ${navbarTransparent}`}>
      <Container>
        <Row justify="space-between">
          <a aria-label="homepage" href="#page-top">
            {/* <img src={`/img/logo.png`} alt="logo" width="230spx" height="55px" /> */}
            <LogoText>EcoPurse</LogoText>
          </a>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger className={`${navbarTransparent}`} onClick={toggleButton}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={toggleButton}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={toggleButton}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
